import React from 'react';

import Autosuggest from 'react-autosuggest';
import SearchResult from "./SearchResult";

const renderSuggestion = (result) => <SearchResult result={result} />;

const MIN_QUERY_LENGTH = 2;

let abortController;

const fetchSuggestions = (query, loadingFunc, suggestionFunc) => {
  if (abortController) {
    abortController.abort(); // Abort any in-progress fetches
    abortController = null;
  }
  if (!query || query.length < MIN_QUERY_LENGTH) {
    suggestionFunc([]);
    loadingFunc(false);
    return;
  }
  loadingFunc(true);

  abortController = new AbortController();
  const abortSignal = abortController.signal;

  const data = {
    q: query
  };

  fetch(
    '/search', {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: abortSignal
    }
  ).then(
    response => {
      loadingFunc(false);
      if (response.ok) {
        return response.json();
      }
      else {
        suggestionFunc([{
          '_index': 'error',
          '_source': {
            error: response.statusText
          }
        }]);
        throw new Error(response.statusText);
      }
    }
  ).then(
    data => {
      suggestionFunc(data);
      abortController = null;
    }
  ).catch(
    err => {
      if (!err instanceof DOMException) {
        loadingFunc(false);
        // DOMException raised when fetch aborts
        throw(err);
      }
    }
  );
};

const getSuggestionValue = (suggestion) => {
  return suggestion['_source']['gid'] || '';
};

const Omnisearch = () => {

  const [suggestions, setSuggestions] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [queryString, setQueryString] = React.useState('');

  const renderInputComponent = inputProps => (
    <div className='input-group'>
      <input
        type="text"
        autoComplete="off"
        aria-autocomplete="list"
        aria-controls="react-autowhatever-1"
        {...inputProps}
      />
      <div className='input-group-append'>
        <button
          className='btn btn-primary'
          disabled={isLoading}
          onClick={() => fetchSuggestions(queryString, setLoading, setSuggestions)}
        >
          <span
            className={`fa fa-fw ${ isLoading ? 'fa-spin fa-spinner' : 'fa-search' }`}
          />
        </button>
      </div>
    </div>
  );

  return (
      <Autosuggest
        inputProps={{
          className: 'form-control',
          onChange: (e) => setQueryString(e.target.value),
          placeholder: 'Search Concertim Center...',
          value: queryString || ''
        }}
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion
        onSuggestionsClearRequested={
          () => setSuggestions([])
        }
        onSuggestionsFetchRequested={
          ({ value }) => fetchSuggestions(value, setLoading, setSuggestions)
        }
        onSuggestionSelected={
          (evt, { suggestion }) => {
            evt.stopPropagation();
            evt.preventDefault();

            if (suggestion['_index'] === 'error') {
              return false;
            }
            const url = suggestion['_source'].url;
            if (typeof(window) !== 'undefined' && url) {
              window.location = url;
            }
          }
        }
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        suggestions={suggestions}
      />
  );
};

export default Omnisearch;
