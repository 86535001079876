import React from 'react';

const classesMapping = {
  "Operations Team": "btn-success",
  "Engineering Team": "btn-warning",
  "Consultancy Team": "btn-danger",
};

export default ({ model, associations, consultancyPossible }) => {
  let question = model._source['question'];
  let answer = model._source['answer'];
  const highlighting = model['highlight'];
  if(highlighting != undefined) {
    if(highlighting['question']) question = highlighting['question'];
    if(highlighting['answer']) answer = highlighting['answer'];
  }

  return (
    <div className="card faq-item-card" id={`faq-card-${model._source["id"]}`}>
      <div className="card-body">
        <h4 className="faq-item-question card-title" dangerouslySetInnerHTML={{__html: question}}></h4>
        <p className="faq-item-answer card-text" dangerouslySetInnerHTML={{__html: answer}}></p>
        { FaqItemCardButton(model, associations, consultancyPossible) }
      </div>
    </div>
  );
}

function FaqItemCardButton(model, associations, consultancyPossible) {
  const team = model._source["engineering_team"];
  const disabled = team === "First Team" && consultancyPossible === "false";
  const classes = `btn ${classesMapping[team]} faq-item-link`;
  const text = `Create case with our ${team.toLowerCase()}`;
  if(disabled) {
    return(
        <a className={`${classes} disabled`} title={"Insufficient credits to create consultancy case"}>
          {text}
        </a>
    )
  } else {
    const linkPath = `faq-items/${model._source["front_end_id"]}${associations}`;
    return(
        <a className={classes} href={linkPath}>
          {text}
        </a>
    )
  }
}

